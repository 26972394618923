// Don't bother putting new departments in alpahbetical order, the component that uses this array sorts them anyway.
export const departments = [
  "Alcuin College",
  "Archaeology",
  "Biology",
  "Borthwick Institute for Archives",
  "Centre for Applied Human Rights",
  "Centre for Eighteenth Century Studies",
  "Centre for Health Economics",
  "Centre for Housing Policy",
  "Centre for Medieval Studies",
  "Centre for Reviews and Dissemination",
  "Centre for Women's Studies",
  "Chemistry",
  "Commercial Services",
  "Computer Science",
  "Constantine College",
  "Corporate and Information Services",
  "Derwent College",
  "Economics",
  "Education",
  "Electronic Engineering",
  "English and Related Literature",
  "Environment and Geography",
  "Estates and Campus Services",
  "Finance",
  "Goodricke College",
  "Halifax College",
  "Health Sciences",
  "History",
  "History of Art",
  "Hull York Medical School",
  "Human Resources",
  "Humanities Research Centre",
  "Institute for Effective Education",
  "James College",
  "Language and Linguistic Science",
  "Languages for All",
  "Langwith College",
  "Library and Archives", 
  "Marketing",
  "Mathematics",
  "Music",
  "Natural Sciences",
  "Norwegian Study Centre",
  "Open Door Team",
  "Philosophy",
  "Physics",
  "Politics",
  "Psychology",
  "Research and Enterprise",
  "Social Policy and Social Work",
  "Sociology",
  "Student and Academic Services",
  "Students Union",
  "The York Law School",
  "The York Management School",
  "Theatre, Film and Television",
  "International Pathway College",
  "Vanbrugh College",
  "Vice Chancellor's Department",
  "Wentworth College",
]
