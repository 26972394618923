import React from "react"
import Layout from "../components/layout"
import AddArchiveForm from "../components/addarchiveform"

class Add extends React.Component {
  render() {
    return(
      <React.Fragment>
        <Layout>
          <AddArchiveForm />
        </Layout>
      </React.Fragment>
    );
  }
}

export default Add
